// extracted by mini-css-extract-plugin
export var chatGptExpertiseCases__between = "dz_CS";
export var chatGptExpertiseCases__card = "dz_CX";
export var chatGptExpertiseCases__contentAll = "dz_C2";
export var chatGptExpertiseCases__contentOne = "dz_C0";
export var chatGptExpertiseCases__contentTwo = "dz_C1";
export var chatGptExpertiseCases__description = "dz_CW";
export var chatGptExpertiseCases__imageDesktop = "dz_CT";
export var chatGptExpertiseCases__title = "dz_CV";
export var chatGptExpertiseCases__wrapperLeft = "dz_CY";
export var chatGptExpertiseCases__wrapperRight = "dz_CZ";